import React from "react"
import Helmet from "react-helmet"

import Img from "gatsby-image/withIEPolyfill"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const IndexPage = ({data}) => {
  return (
  <Layout>
    <Helmet>
      <script src="https://donorbox.org/widget.js" paypalExpress="false"></script>
    </Helmet>
    <SEO title="Home" />
    <div className="section container anchor">
      <h1 className="is-size-1">Welcome Home at Sugar Land Memorial Park</h1>
      <div className="columns">
        <div className="column is-8">
          <p>The Welcome Home project will present a series of bronze family scenes. Each scene is intended to capture a unique story which welcomes home military personnel from their active service. Each scene will be inspired by a real Sugar Land military family.</p>
          <p>We are asking military families who live within the corporate limits of Sugar Land to submit pictures with accompanying stories that visually show loved ones welcoming home active service members. Selected photos will be used as the subject matter for the creation of the bronze statues</p>
          <p>Importantly, this project can’t come to life without your generous contributions. <strong>Donate today!</strong></p>
          <p>Contact the Sugar Land Legacy Foundation with questions at <a href="mailto:sugarlandlegacy@gmail.com">sugarlandlegacy@gmail.com</a> or call/text <a href="tel:+18327798609">832&#8209;779&#8209;8609</a>.</p>
        </div>
        <div className="column is-4">
          <Img className="content-image" fluid={data.welcomehome.childImageSharp.fluid} />
        </div>
      </div>

      <div className="columns">
        <div className="column is-6 inset">
          {/* <h2 className="is-size-1" style={{ marginTop: 0}}>We want to hear from you</h2> */}
          <iframe frameborder="0" height="935px" name="donorbox" scrolling="yes" seamless="seamless" src="https://www.surveymonkey.com/r/2TGXYVH" style={{maxWidth: '100%', minWidth: '100%', maxHeight: 'none !important' }} width="100%"></iframe>
        </div>
        <div className="column is-6 inset">
          <h2 className="is-size-1" style={{ marginTop: 0}}>Make a donation</h2>
          <iframe allowpaymentrequest="" frameborder="0" height="900px" name="donorbox" scrolling="no" seamless="seamless" src="https://donorbox.org/embed/welcome-home-campaign-at-sugar-land-memorial-park" style={{maxWidth: '100%', minWidth: '100%', maxHeight: 'none !important' }} width="100%"></iframe>
        </div>
      </div>
    </div>
  </Layout>
)}

export default IndexPage

export const indexQuery = graphql`
  query {
    welcomehome: file(relativePath: {eq: "iStock-1137016906.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1024){
          ...GatsbyImageSharpFluid_tracedSVG
          aspectRatio
        }
      }
    }
  }
`